import React,{useState} from 'react'
import { connect } from 'react-redux'

import PropTypes from 'prop-types'
import Toast from 'react-bootstrap/Toast'

import {tostReset} from "../../services/actions"


function ToastObj(props) {
  const [show,setShow]= useState(true);
  const close = ()=>{
    if(props.close){
      props.close();
    }
    setShow(false);
    props.tostReset();
  }
  return (
    <>
    <div className="position-fixed" style={{top:'15px',right:'10px',zIndex:'10000'}}>
      <Toast onClose={() => close()} show={show} delay={3000} className={`bg-${props.type} text-white`} autohide>
        <Toast.Body>{props.message}</Toast.Body>
      </Toast>
    </div>
    </>
  )
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = {tostReset}

export default connect(mapStateToProps, mapDispatchToProps)(ToastObj)
