import React, { useEffect } from "react";
import { connect } from "react-redux";
import {PH_ROCK_CONTRACT} from "../../../services/constants"
import {metamaskLogin, rockOwner, mintRock } from "../../../services/actions"
import {openseaUrl} from '../../../utils/index'

const Rocks = (props) => {
  const {user,ph_rock_owners,mintPhRock,info,price } = props

  useEffect(() => {
    props.rockOwner(info.token_id)
  }, [])

  const handleMintNow = () => {
    if (user.login) {
      props.mintRock(info.token_id,user.user.eth_address)
    }
    else {
      props.metamaskLogin();
    }
  }

  let eth_price = price / (10 ** 18)

  let getButton =()=>{
    if (typeof window.ethereum !== 'undefined') {
      if(typeof mintPhRock.data[info.token_id] !='undefined' && mintPhRock.data[info.token_id].type =='request'){
        return (<p><a href="#!"  button="" id="" className="btn btn-primary btn-block"  style={{padding:"0.2rem"}}>Wait...</a> </p>)
      }

      if (typeof ph_rock_owners.data[info.token_id] =='undefined' || ph_rock_owners.data[info.token_id] == false) {
      return (<p><a href="#!" onClick={() => handleMintNow()} button="" id="" className="btn btn-primary btn-block"  style={{padding:"0.2rem"}}>Buy Now  {eth_price}</a> </p>)
      }
      else{
        return (<p><a href={openseaUrl(`/assets/${PH_ROCK_CONTRACT}/${info.token_id}`)} target="_blank"  button="" id="" className="btn btn-primary btn-block"  style={{padding:"0.2rem"}}>View</a></p>)
      }
    }else {
        return (<p><a href="#!" onClick={() => handleMintNow()}  className="btn btn-primary btn-block" style={{padding:"0.2rem"}}>???</a></p>)
      }
  }
  

  return (
    <>
      <p className="nonActiveRock" key={info.token_id}>
        <img src={info.image} width="100%" height="150px" align="middle" />
        <h5>{info.name}</h5>
        <h5>NO INFO {info.token_id}</h5>
        {getButton()}
      </p>
    </>
  )
}


const mapStateToProps = (state) => ({
  user: state.user,
  ph_rock_owners: state.ph_rock_owners,
  mintPhRock: state.mintPhRock,
})

const mapDispatchToProps = {
  metamaskLogin,
  rockOwner, 
  mintRock,
}

export default connect(mapStateToProps, mapDispatchToProps)(Rocks)

