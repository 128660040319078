import React,{useEffect} from 'react';
import { connect } from "react-redux";
import { metamaskLogin,checkLogin } from '../../services/actions';


function ConnectBtn(props) {
  const {user} = props;

  useEffect(()=>{
    props.checkLogin();
  },[]);
  return (
    <div id="connectbutton">
      {user.login ?
        (
          <>
            <button id="enableEthereumButton" disabled>CONNECTED TO METAMASK</button>
            <p style={{marginTop:".5rem"}}>ETH Address: <b>{user.user.eth_address}</b></p>
          </>
        )
        :
        (
          <button id="enableEthereumButton" onClick={() => { props.metamaskLogin() }}>CONNECT TO METAMASK</button>
        )
      }

  
    </div>
  )
}


const mapStateToProps = (state) => ({
  user: state.user,
})

const mapDispatchToProps = {
  metamaskLogin,
  checkLogin
}

export default connect(mapStateToProps, mapDispatchToProps)(ConnectBtn)
