

export function getRocksReducer(state = { type: '', data: [], message: "" }, action) {
  switch (action.type) {
    case "GET_ROCKS_REQUEST":
      return { type: 'request', data: [], message: "" }
      break;
    case "GET_ROCKS_SUCCESS":
      return { type: 'success', data: action.json, message: "" }
      break;
    case "GET_ROCKS_ERROR":
      return { type: 'error', data: [], message: "" }
      break;
  }
  return state;
}


export function phRockOwnerReducer(state = { data: {}}, action) {
  let obj;
  switch (action.type) {
    case "OWNER_SUCCESS":
      obj = {...state};
      obj.data[action.data.token_id]=action.data.owner;
      return obj
      break;
    case "OWNER_ERROR":
      obj = {...state};
      obj.data[action.data.token_id]=false;
    
      return obj;
      break;

  }

  return state;
}


export function mintPhRockReducer(state = { data: {} }, action) {
  let obj;
  switch (action.type) {
    case "MINT_REQUEST":
      obj = {...state}
      obj.data[action.json.data.token_id]={};
      obj.data[action.json.data.token_id]['type']="request";
      obj.data[action.json.data.token_id]['data']=action.json.data;
      return obj
      break;
    case "MINT_SUCCESS":
      obj = {...state}
      obj.data[action.json.data.token_id]={};
      obj.data[action.json.data.token_id]['type']="success";
      obj.data[action.json.data.token_id]['data']=action.json.data;
      return obj
      break;
    case "MINT_ERROR":
      obj = {...state}
      obj.data[action.json.data.token_id]={};
      obj.data[action.json.data.token_id]['type']="error";
      obj.data[action.json.data.token_id]['data']=action.json.data;
      return obj
      break;

  }

  return state;
}

export function rockPriceReducer(state = { price:""  }, action) {
  switch (action.type) {
    case "PRICE_SUCCESS":
      return { type: 'success', price: action.data.price, message: "" }
      break;
    case "PRICE_ERROR":
      return { type: 'error', price: "", message: "" }
      break;

  }

  return state;
}

