import React,{useEffect} from 'react';
import { connect } from "react-redux";
import Item from "./Item";

import {getRocks} from "../../services/actions";

function Rocks(props) {
  const {get_rocks,rock_price} = props;
  useEffect(() => {
    props.getRocks();
  }, []);

  const items = get_rocks.data.map((data, index) => {
    return ( <Item info={data} price={rock_price.price} key={index}/>)
  })

  return (
    <div>
      {get_rocks.type != "request" ?
        (items)
        :
        (<h3 className="text-center text"> Loading....</h3>)
      }
    </div>
  )
}

const mapStateToProps = (state) => ({
  user: state.user,
  get_rocks: state.get_rocks,
  rock_price: state.rock_price
})

const mapDispatchToProps = {getRocks}

export default connect(mapStateToProps, mapDispatchToProps)(Rocks)

