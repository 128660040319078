import React from 'react'
import { connect } from 'react-redux'
import ConnectBtn from '../ConnectBtn'
import Toast from '../Toast'


export const Header = (props) => {
  const {user,tost} = props;
  return (
    <>
       <h3>Flipped Ether Phrocks</h3>
        <p>Launched in 2021, Flipped EtherPhrock is one of the first crypto collectible NFT-membership projects. Only 100 Flipped Phrocks can ever be available. This proof of degeneracy (PoD) is built entirely on the Ethereum blockchain and is 100% on-chain verifiable, with a decentralized smart contract (deployed here) used to manage almost everything.</p>
        <p>This may be a disgusting cash grab but the proceeds of Flipped EtherPhrocks will be used to fund a “Meeting of the Rocks” hosted at the luxurious Pebble Beach, California.</p>
        <br />

        <ConnectBtn/>
       {tost.type!="" &&
          <Toast message={tost.message} onClose={() =>{} } type={tost.type}/>
        }

      <br />
    </>
  )
}

const mapStateToProps = (state) => ({
  user: state.user,
  tost: state.tost,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Header)
