import { combineReducers } from 'redux';

import {getRocksReducer,phRockOwnerReducer,mintPhRockReducer,rockPriceReducer} from './list';
import {userReducer,tostReducer} from './user';

const rootReducer = combineReducers({
    user:userReducer,
    tost:tostReducer,
    rock_price:rockPriceReducer,
    get_rocks: getRocksReducer,
    ph_rock_owners: phRockOwnerReducer,
    mintPhRock: mintPhRockReducer,
    
});

export default rootReducer;