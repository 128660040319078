export function userReducer(state = { login: false, user: false,network:true }, action) {
  switch (action.type) {
    case "LOGIN_SUCCESS":
      let obj = { login: true, user: action.data.user,network:true }
      return obj
      break;
    case "LOGIN_NETWORK_ERROR":  
       return { login: false, user: false,network:false }
      break;
    case "LOGOUT_SUCCESS":  
       return { login: false, user: false,network:true} 
  }
  return state;
}


export function tostReducer(state = { type:"",message:"" }, action) {
  switch (action.type) {
    case "TOST_REST":
      return { type:"",message:"" }
    case "TOST_SET":
      let obj = {"type":action.tost_type,message:action.message}
      return obj
      break;
  }
  return state;
}
