import React from 'react'

function Item(props) {
  const {faq}= props;
  return (
     <>
      <p>
        <strong>{faq.title}</strong>
      </p>
      <blockquote>{faq.details}</blockquote>
     </>
  )
}

export default Item
