import {connect,checkOwnerOf,mintNow} from "../../utils/ethereum"
import { fetchReq } from './base';

export function metamaskLogin() {
  return dispatch => {
      connect(dispatch)
  };
}

export function checkLogin() {
  return dispatch => {
    if (typeof window.ethereum !== 'undefined') {
      metamaskLogin()(dispatch);
    }
  }
}

export function tostReset() {
  return dispatch => {
    dispatch({type:"TOST_REST"});
  };
}

export function mintRock(token_id,eth_address) {
  return dispatch => {
    mintNow(token_id,eth_address,dispatch);
  }
}


export function rockOwner(token_id) {
  return dispatch => {
    checkOwnerOf(token_id,dispatch);
  }
}

export function getRocks() {
  return fetchReq('api/v1/show_all_phrocks', 'GET_ROCKS');
};


