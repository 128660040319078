import {ETH_ENV} from '../services/constants'
// import {web3} from "./ethereum"

// for making helper functions

export function shortEthAddress(address){
  let addr = web3.utils.toChecksumAddress(address);
  return addr.substr(0,6)+"..."+addr.substr(addr.length-4,4);
}

export function ValidateEmail(email) 
{
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}


export function etherUrl(url) {
    if (ETH_ENV == 'production') {
      return "https://etherscan.io"+url;  
    } else {
      return "https://rinkeby.etherscan.io"+url; 
    }
}

export function openseaUrl(url) {
    if (ETH_ENV == 'production') {
      return "https://opensea.io"+url;  
    } else {
       return "https://testnets.opensea.io"+url; 
    }
}

