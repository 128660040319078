import detectEthereumProvider from '@metamask/detect-provider';
import * as contents from "../services/constants"
import PH_ROCK_ABI from "../services/abi/PH_ROCK_ABI";

const ethereum = window.ethereum;
export const web3 = new Web3();
export async function connect(dispatch){
  if (typeof window.ethereum === 'undefined') {
    window.open("https://metamask.app.link/dapp/"+contents.DOMAIN_NAME.replace("https://", "").replace("http://", "")+"/dashboard?login=metamask");
    return
  } 

  ethereum.request({ method: 'eth_requestAccounts' }).then(accounts => {
    ethereum.on('accountsChanged', function (accounts) {
      window.location.reload();
    });

    if (ethereum.isMetaMask && ethereum.selectedAddress != null) { 
        detectEthereumProvider().then(provider=>{
          web3.setProvider(provider);
          
          web3.eth.net.getNetworkType().then(async(network_type) => {
            let network = {}
            network['type'] = network_type;
            network['chainId'] = await ethereum.request({ method: 'eth_chainId' });
            if (network_type == (contents.ETH_ENV == 'development' ? 'rinkeby' : 'main')) { 
              ethPrice(dispatch)
              dispatch({type:"LOGIN_SUCCESS",data:{login: true, user: {eth_address:ethereum.selectedAddress},network:true }});
              dispatch({type:"TOST_SET",tost_type:"success",message:"Logged in successfully! "});
            }else{
              dispatch({type:"LOGIN_NETWORK_ERROR",data:{login: false, user: {eth_address:ethereum.selectedAddress},network:false }});
              dispatch({type:"TOST_SET",tost_type:"danger",message:"Please check your network."});
            }
          })
        });
    }else{
      dispatch({type:"TOST_SET",tost_type:"danger",message:"Some error occurred!"});
    } 
  }).catch((error)=>{
    dispatch({type:"TOST_SET",tost_type:"danger",message:error.message});
  });
}  


export async function checkOwnerOf(token_id,dispatch) {
  const contract = new web3.eth.Contract(PH_ROCK_ABI, contents.PH_ROCK_CONTRACT);
    try {
      const owner = await contract.methods.ownerOf(token_id).call();
      dispatch({ type: "OWNER_SUCCESS", data: { owner: owner, token_id: token_id } })
    } catch (error) {
      dispatch({ type: "OWNER_ERROR", data: { owner: null, token_id: token_id, message: error.message } })
    }
}


export async function ethPrice(dispatch) {
  const contract = new web3.eth.Contract(PH_ROCK_ABI, contents.PH_ROCK_CONTRACT);
    try {
      const eth_price = await contract.methods.eth_price().call();
      dispatch({ type: "PRICE_SUCCESS", data: { price: eth_price } })
    } catch (error) {
      dispatch({ type: "PRICE_ERROR", data: { data: error } })
    }
}

export async function mintNow(token_id,user_address, dispatch) {
    let tx;
    const contract = new web3.eth.Contract(PH_ROCK_ABI, contents.PH_ROCK_CONTRACT);
    dispatch({
      type: 'MINT_REQUEST',
      json: { type: "request", data: { token_id: token_id } }
    })

    try {
      const price = await contract.methods.eth_price().call();
      tx = await contract.methods.mint(token_id).send({ from: user_address, value: price })
      let finalTx = await web3.eth.getTransactionReceipt(tx.transactionHash);

      dispatch({type:"TOST_SET",tost_type:"success",message:"Token ID: "+token_id+" successfully minted!"});
      checkOwnerOf(token_id,dispatch);
      return dispatch({
        type: 'MINT_SUCCESS',
        json: { type: "success", data: { token_id: token_id,finalTx: finalTx } }
      })
    } catch (error) {
      dispatch({type:"TOST_SET",tost_type:"danger",message:error.message});
      return dispatch({
        type: 'MINT_ERROR',
        json: { type: "error", data: {token_id: token_id,error:error.message}}
      })
    }
}
