import React from "react";

import Header from "../../components/Header";
import Rocks from "../../components/Rocks";
import Faq from "../../components/Faq";
import Footer from "../../components/Footer";

export const Home = (props) => {
  return (
    <>
      <section style={{ height: "100vh", backgroundColor: "#e5f3ff" }} >
        <div className="container mt-2 main_container">
          <div className="row d-flex align-item-center">
            <div className="col-lg-8 maintable" >
              <Header/>
              <div>
              <Rocks/> 
              </div>
               <Faq/> 
              <Footer/>
            </div>
           
          </div>
        </div>
      </section>
    </>
  )
}


export default Home
