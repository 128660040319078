import React,{useState} from 'react'

import Item from "./Item";
import PropTypes from 'prop-types'

function Faq(props) {
  const [qaIsHidden, setQaIsHidden] = useState(true);
  let data =[
    {
      title:"Why the wrapper?",
      details:"What wrapper?"
    },
    {
      title:"What does the wrapper do?",
      details:"It was useful in a previous age of rocks."
    },
    {
      title:"Is the bug gone?",
      details:"Yes"
    },
    {
      title:"Is the contract marketplace shut down?",
      details:"OG Rocks problem. Not ours."
    },
    {
      title:"Why?",
      details:"There was a huge gap in the market for right facing, left facing rocks."
    },
    {
      title:"Why right facing, left facing rocks?",
      details:"Do you know what the latin word for left is? Neither did we. Right facing, left facing rocks is all you need to know."
    },
    {
      title:"Revolutionary?",
      details:"Exactly by 1."
    },
    {
      title:"Is this a low effort clone?",
      details:"No lol. And we learned something so, double worth it!"
    },
    {
      title:"What are they?",
      details:"They're rocks, degen."
    },
    {
      title:"To the moon?",
      details:"Duh"
    },
    {
      title:"Why should I buy a phrock?",
      details:"Only if you want to be invited to the citadel."
    },
    {
      title:"How do I get an invite to the Citadel?",
      details:"Info coming soon (TM)."
    }
  ]

  let all_items = data.map((v,i)=>{
    return <Item faq={v} key={i}/>
  })

  return (
    <div>
       <div>
         <h4>Q & A</h4>
        </div>
        <button onClick={() => { setQaIsHidden(false) }} className={qaIsHidden ? "d-block" : "d-none"} >Open Q &amp; A</button>

        <div className={qaIsHidden ? "d-none" : "d-block"}>
            {all_items}
        </div>
    </div>
  )
}

Faq.propTypes = {

}

export default Faq

