import React, {Suspense,StrictMode} from "react";
import { Provider } from "react-redux";
import configureStore from "./services/configureStore";

import Home from "./pages/Home";

const store = configureStore();

function App() {
  return (
    <StrictMode>
      <Provider store={store}>
        <Suspense fallback={null}>
          <Home />
        </Suspense>
      </Provider>
    </StrictMode>

  );
}

export default App;
